import React, {useEffect, useState} from 'react';
import ContainerLoader from "../../Components/Loader/Loader";
import axios from "axios";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins, faPlus, faEdit} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import responsiveInputs from "../../Components/Inputs/ResponsiveInput";
import Button from "react-bootstrap/Button";
import ShowAlert from "../../Components/Alert/ShowAlert";
import {Modal} from "react-bootstrap";
// import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5003';

const EditAction = (props) => {

    const actionId = parseInt(props.match.params.number, 10);
    const [showAlert, setShowAlert] = useState({variant: 'success', show: false, message: 'Gooood!'});
    const [isLoaded, setIsLoaded] = useState(false);
    const [action, setAction] = useState({
        id: '',
        type: '',
        summ: '',
        currency: '',
        user: '',
        description: '',
        date: '0000-00-00',
        is_deleted: 0
    });
    const [error, setError] = useState(null);

    const [modalShow, setModalShow] = useState(false);
    const [display, setDisplay] = useState("none");

    const openModal = () => {
        setModalShow(true);
        setDisplay('block');
    };

    const closeModal = () => {
        setModalShow(false);
        setDisplay('none')
    };

    useEffect(() => {
        fetch(apiUrl + '/actions/' + actionId, { headers: { Authorization: 'WellcomeMyFriend' } })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);

                    const dateTimeFormat = new Intl.DateTimeFormat('fr-CA', {year: 'numeric', month: '2-digit', day: '2-digit'});
                    let adate = dateTimeFormat.format(new Date(result.date));
                    // let adate = date_to_show(result.date);
                    //  setAction({...result, date:adate});
                    setAction({...result, date: adate});
                },)
            .catch(err => console.error(err));
    }, []);

    const handleChange = event => {
        const {name, value} = event.target;
        setAction({...action, [name]: value});
    };

    const handleSubmit = event => {
        event.preventDefault();
        {
            const values = {
                id: action.id,
                type: action.type,
                summ: action.summ,
                currency: action.currency,
                user: action.user,
                description: action.description,
                date: action.date,
                is_deleted: action.is_deleted
            };
            console.log(values);
            axios.put(apiUrl + `/actions/` + action.id, values, { headers: { Authorization: 'WellcomeMyFriend' } })
                .then((response) => {
                    console.log(response);
                    setShowAlert(
                        {
                            variant: response.data.success ? 'danger' : 'success',
                            show: true,
                            message: "Операция сохранена"
                        }
                    );
                    window.location.href = '/list'
                })
                .catch(err => console.error(err))
        }
    };

    // HARD DELETE
    // const handleDelete = event => {
    //     event.preventDefault();
    //     {
    //         console.log("delete : ", actionId);
    //         axios.delete(apiUrl+'/actions/'+actionId)
    //             .then((response) => {
    //                 console.log(response);
    //                 window.location.href = '/list'
    //         });
    //     }
    // };

    // SOFT DELETE
    const handleDelete = event => {
        event.preventDefault();
        {
            const values = {
                id: action.id,
                type: action.type,
                summ: action.summ,
                currency: action.currency,
                user: action.user,
                description: action.description,
                date: action.date,
                is_deleted: 1
            };
            axios.put(apiUrl + `/actions/` + action.id, values, { headers: { Authorization: 'WellcomeMyFriend' } })
                .then((response) => {
                    setShowAlert(
                        {
                            variant: response.data.success ? 'danger' : 'success',
                            show: true,
                            message: "Операция удалена"
                        }
                    );
                    window.location.href = '/list'
                })
                .catch(err => console.error(err))
        }
    };

    if (!isLoaded) {
        return (
            <div>
                <ContainerLoader/>
            </div>
        )
    }

    return (
        <Container>
            <div className="m-2">
                <span className='opHeader'><FontAwesomeIcon icon={faCoins} size='2x' color='gray'/>{' '}
                    <FontAwesomeIcon icon={faEdit} size='2x' color='gray'/>{' редактирование операции'}
                </span>
            </div>
            <Form className="m-md-5 m-sm-1"
                  onSubmit={handleSubmit}>
                <Form.Row>
                    <Col xs={12} sm={4} md={4}>
                        <Form.Group>
                            <InputGroup size="lg">
                                <InputGroup.Prepend
                                    className={responsiveInputs.prepend}>
                                    <InputGroup.Text>+</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    size="lg"
                                    name="type"
                                    type="text"
                                    value={action.type}
                                    onChange={handleChange}
                                    as='select'>
                                    <option>...</option>
                                    <option>+</option>
                                    <option>-</option>
                                </Form.Control>
                                <Form.Control
                                    className="w-25"
                                    size="lg"
                                    name="summ"
                                    type="num"
                                    value={action.summ}
                                    onChange={handleChange}
                                />
                                <Form.Control
                                    className="w-25"
                                    size="lg"
                                    name="currency"
                                    type="text"
                                    value={action.currency}
                                    onChange={handleChange}
                                    as='select'>
                                    <option>EUR</option>
                                    <option>USD</option>
                                    <option>UAH</option>
                                </Form.Control>
                                {/*<InputGroup.Append*/}
                                {/*    className={responsiveInputs.prepend}>*/}
                                {/*    <InputGroup.Text>€</InputGroup.Text>*/}
                                {/*</InputGroup.Append>*/}
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="user">
                            <Form.Control
                                size="lg"
                                name="user"
                                type="text"
                                value={action.user}
                                placeholder='подпись'
                                onChange={handleChange}
                                as='select'>
                                <option>pavel</option>
                                <option>voglas</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="date">
                            <Form.Control
                                size="lg"
                                name="date"
                                type="date"
                                value={action.date}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={8} md={8}>
                        <Form.Group controlId="description">
                            <InputGroup>
                                <Form.Control
                                    size="lg"
                                    name="description"
                                    type="text"
                                    value={action.description}
                                    placeholder='Описание'
                                    onChange={handleChange}
                                    as='textarea'
                                    rows='3'
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Button href='/list'
                            className="mx-auto mb-2"
                            variant="outline-secondary">
                        Назад
                    </Button>
                    <Button className="mx-auto mb-2"
                            variant="outline-danger"
                            onClick={openModal}>
                        Удалить
                    </Button>
                    <Button className="mx-auto mb-2"
                            variant="outline-primary"
                            type="submit">
                        Сохранить
                    </Button>

                </Form.Row>
            </Form>

            <Modal size="sm" show={modalShow} onHide={closeModal} aria-labelledby="size-sm">
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Подтвердите действие</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="m-md-5 m-sm-1"
                          onSubmit={handleDelete}>
                        <Form.Row>
                            <Button className="mx-auto mb-2"
                                    variant="outline-success"
                                    type="submit">
                                Да
                            </Button>

                            <Button className="mx-auto mb-2"
                                    variant="outline-danger"
                                    onClick={closeModal}>
                                Назад
                            </Button>
                        </Form.Row>
                    </Form>
                </Modal.Body>
            </Modal>
            {showAlert.show && <ShowAlert variant={showAlert.variant} message={showAlert.message} show={showAlert.show}/>}
        </Container>
    )
};
export default EditAction
