import React from "react";
import Spinner from "react-bootstrap/Spinner"
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const ContainerLoader = () => {
    return (
        <Container fluid="lg">
            <Row className="text-center my-5">
                <Col>
                    <Spinner
                        animation="border"
                        variant="secondary"
                        size="lg"
                    >
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
            </Row>

        </Container>
    )
};

export default ContainerLoader;
