import React from "react";
import {useContext} from "react";
import AdminNavbar from "../../Components/Navbar/AdminNavbar";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Main from "../Main/Main";
import ActionList from "../../Components/Actions/actionList";
import UserList from "../../Components/Users/userList";
import EditAction from "../Edit/editAction";
import NewAction from "../New/newAction";
import Login from "../Login/Login";
import {AuthContext} from "../../Components/Context/context";
import SpecNewAction from "../New/specNewAction";
import NewExchange from "../New/newExchange";
import NewInchange from "../New/newInchange";

export default function ProtectedResources() {

    const authContext = useContext(AuthContext);

    return authContext.access === 'granted' &&
        <div>
            <div>
                <AdminNavbar/>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Main/>
                        </Route>
                        <Route exact path="/list">
                            <ActionList/>
                        </Route>
                        <Route exact path="/userlist">
                            <UserList/>
                        </Route>
                    </Switch>

                    <Route path="/action/edit/:number" component={EditAction}/>

                    <Route exact path='/action/new'>
                        <NewAction/>
                    </Route>

                    <Route exact path='/action/exchange'>
                        <NewExchange/>
                    </Route>
                    <Route exact path='/action/inchange'>
                        <NewInchange/>
                    </Route>

                    <Route exact path='/action/neweurin'>
                        {SpecNewAction('+', 'EUR')}
                    </Route>
                    <Route exact path='/action/neweurout'>
                        {SpecNewAction('-', 'EUR')}
                    </Route>
                    <Route exact path='/action/newusdin'>
                        {SpecNewAction('+', 'USD')}
                    </Route>
                    <Route exact path='/action/newusdout'>
                        {SpecNewAction('-', 'USD')}
                    </Route>
                    <Route exact path='/action/newuahin'>
                        {SpecNewAction('+', 'UAH')}
                    </Route>
                    <Route exact path='/action/newuahout'>
                        {SpecNewAction('-', 'UAH')}
                    </Route>

                    <Route path="/api/actions/"/>

                </Router>
            </div>
            {authContext.access !== 'granted' &&
            <div>
                <Router>
                    <Route exact path='/loginpage'>
                        <Login/>
                    </Route>
                </Router>
            </div>}
        </div>;

}