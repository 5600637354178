import * as React from "react";

const IconedTitle = (text, ico) => {
    return (
        <span>
            <i className="d-inline m-1 m-md-2 m-lg-2 m-xl-2">{ico}</i>
            <b className="d-none d-sm-inline d-md-inline d-lg-inline d-xl-inline">{text}</b>
        </span>
    )
};
export default IconedTitle
