import React, {useEffect, useState} from 'react';
import ContainerLoader from "../Loader/Loader";
import Button from "react-bootstrap/Button";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import ToolkitProvider, {ColumnToggle, CSVExport} from 'react-bootstrap-table2-toolkit';

const {ExportCSVButton} = CSVExport;

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5003';

let nameFilter;

const ActionList = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [actions, setActions] = useState([{}]);

    const columns = [
        {
            dataField: 'type',
            headerClasses: 'hidden-xs',
            text: '+',
            sort: true,
            hidden: true,
            headerAlign: 'center',
            filter: textFilter({
                getFilter: (filter) => {
                    nameFilter = filter;
                }
            }),
            footer: 'SUMM'
        }, {
            dataField: 'summ',
            style: {
                fontWeight: 'bold',
            },
            align: 'right',
            text: '∑',
            sort: true,
            headerAlign: 'center',
            filter: textFilter({
                getFilter: (filter) => {
                    nameFilter = filter;
                }
            }),
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
        }, {
            dataField: 'currency',
            text: '£',
            sort: true,
            align: 'left',
            headerAlign: 'center',
            filter: textFilter({
                getFilter: (filter) => {
                    nameFilter = filter;
                }
            }),
        },
        {
            dataField: 'description',
            text: '☰',
            sort: true,
            headerAlign: 'center',
            style: {
                fontSize: '14px',
            },
            filter: textFilter({
                getFilter: (filter) => {
                    nameFilter = filter;
                }
            }),
        },
        {
            dataField: 'date',
            text: 'DATE',
            sort: true,
            headerAlign: 'center',
            style: {
                fontSize: '14px',
            },
            filter: textFilter({
                getFilter: (filter) => {
                    nameFilter = filter;
                }
            }),
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getDate()).slice(-2)}.${('0' + (dateObj.getMonth() + 1)).slice(-2)}.${dateObj.getFullYear()}`;
            },
        },
        {
            dataField: 'user',
            text: 'USER',
            // text: IcoUser,
            sort: true,
            headerAlign: 'center',
            hidden: true,
            filter:
                textFilter({
                    getFilter: (filter) => {
                        nameFilter = filter;
                    }
                }),
        },
        {
            dataField: 'id',
            text: 'ID',
            // text: IcoId,
            sort: true,
            headerAlign: 'center',
            hidden: true
        },
    ];

    const rowStyleRed = (row) => {
        const style = {};
        if (row.type !== '+') {
            style.color = 'red';
            if (row.type==='-'&&row.summ>0)
            {row.summ=(row.summ*-1);}
        } else {
            style.color = 'black';
        }

        return style;
    };

    const defaultSorted = [
        {
            dataField: 'id',
            order: 'desc',
            headerAlign: 'center'
        }
    ];

    const rowEvents = {
        onClick: (e, row, rowIndex, id) => {
            window.location.href = '/action/edit/'+row.id;
        },
    };

    const {ToggleList} = ColumnToggle;

    useEffect(() => {
        fetch(apiUrl + '/actions', { headers: { Authorization: 'WellcomeMyFriend' } })
            .then(res => res.json())
            .then((result) => {
                setIsLoaded(true);
                setActions(result)
            },)
         .catch(err => console.error(err));
    }, []);

    if (!isLoaded) {
        return (
            <div>
                <ContainerLoader/>
            </div>
        )
    }

    return (

        <div>
            <ToolkitProvider
                keyField="id"
                data={actions}
                columns={columns}
                columnToggle
                search
            >
                {
                    props => (
                        <div>
                            <div>
                                <span>
                                    {/*<button className="btn btn-lg btn-primary" onClick={ handleClick }> +</button>*/}
                                    <ToggleList {...props.columnToggleProps} className=" px-1 mt-2 mr-auto mb-2"/>
                                    <Button
                                        className="mt-2 mr-1 mb-2 float-right"
                                        type="link"
                                        href="/action/new"
                                        variant="outline-primary"
                                    >
                                        <strong>+</strong>
                                        {/*<FontAwesomeIcon icon={faPlus} size='1x'/>*/}
                                </Button>
                                </span>
                            </div>

                            <BootstrapTable
                                {...props.baseProps}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                rowEvents={rowEvents}
                                rowStyle={rowStyleRed}
                                rowClasses='table-row'
                                headerClasses="header-class"
                                condensed
                                striped
                                bordered={false}
                                filter={filterFactory()}
                            />
                            <ExportCSVButton
                                {...props.csvProps}>
                                Скачать таблицу
                            </ExportCSVButton>
                        </div>
                    )
                }
            </ToolkitProvider>
        </div>
    )
};
export default ActionList
