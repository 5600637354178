import React, {useEffect, useState} from "react";
import Alert from 'react-bootstrap/Alert'

const ShowAlert = (props) => {
    const [show, setShow] = useState(props.show)

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <Alert variant={props.variant} show={show} onClose={() => setShow(false)} dismissible>
            {props.message}
        </Alert>
    )

};
export default ShowAlert;