import React, {useEffect, useState} from 'react';
import ContainerLoader from "../Loader/Loader";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import axios from 'axios';

const UserList = () =>  {

    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
    const [actions, setActions] = useState([]);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5003' ;

    useEffect(() => {
        fetch(apiUrl+'/users', { headers: { Authorization: 'WellcomeMyFriend' } })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded (true);
                    setUsers (result)
                })
            .catch(err =>
               { setError(err);
                console.error(err)}
            );
    }, []);

    if (!isLoaded) {
        return (
            <div>
                <ContainerLoader/>
                {console.log(error)}
            </div>
        )
    }

    return (
        <div>
            <Table responsive="sm" striped borderless hover size="sm">
                <thead>
                    <tr>
                        {['login', 'role', 'lastlog', 'del'].map((item, index) => (
                            <th key={index}>
                                {item}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td><h4>{user.login}</h4></td>
                            <td>{user.role}</td>
                            <td>{user.lastlog}</td>
                            <td>
                                <Badge variant="danger">x</Badge>{' '}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {console.log(actions)}
        </div>
    )
};
export default UserList
