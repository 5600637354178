import React from "react";
import { useContext } from "react";
import { useState, useEffect } from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ActionList from "../../Components/Actions/actionList";
import UserList from "../../Components/Users/userList";
import CashBox from "../../Components/CashBox/CashBox";


const Main = () =>  {
    return (

<div>
        <CashBox />
</div>

    )
};
export default Main