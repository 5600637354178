import React, {useContext, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ShowAlert from "../../Components/Alert/ShowAlert";
import responsiveInputs from "../../Components/Inputs/ResponsiveInput";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign, faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {getCurrentDate} from '../../Components/CurrentDate/currentDate'
import {AuthContext} from "../../Components/Context/context";

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5003' ;
const today = getCurrentDate();


const NewExchange = () => {

    const [showAlert, setShowAlert] = useState({variant: 'success', show: false, message: 'Gooood!'});
    const authContext = useContext(AuthContext);
    const [inv, setInv] = useState({
        type: '-',
        summ: '',
        currency: '',
        rate: '',
        user: authContext.user,
        description: '',
        date: '0000-00-00',
        is_deleted: 0,
    });
    const [outv, setOutv] = useState({
        type: '+',
        summ: '',
        currency: '',
        rate: '',
        user: authContext.user,
        description: '',
        date: '0000-00-00',
        is_deleted: 0,
    });
    const [error, setError] = useState(null);

    let summOut=0;
    const calculate =(summIn, rate)=>{
        return summOut=summIn*rate;
    };

    useEffect(() => {
        setOutv({...outv, date:today,
            rate: inv.rate,
            summ: calculate(inv.summ, inv.rate),
            description: 'Покупка за '+inv.currency+' по курсу '+inv.rate
        });
        setInv({...inv,  date:today,
                                description: 'Обмен на '+outv.currency+' по курсу '+inv.rate});
        console.log(outv);
        console.log(inv);

    }, [inv.rate, inv.summ, outv.currency, inv.currency]);

    const handleInvChange = event => {
        const {name, value} = event.target;
        setInv({...inv, [name]: value});
    };

    const handleOutvChange = event => {
        const {name, value} = event.target;
        setOutv({...outv, [name]: value});
    };

    const handleSubmit = event => {
        event.preventDefault();
        {
            console.log(inv);
            axios.post(apiUrl + '/actions', inv, { headers: { Authorization: 'WellcomeMyFriend' } })
                .then((response) => {
                    axios.post(apiUrl + '/actions', outv, { headers: { Authorization: 'WellcomeMyFriend' } })
                        .then((response) => {
                            console.log(response);
                            setShowAlert(
                                {
                                    variant: response.data.success ? 'danger' : 'success',
                                    show: true,
                                    message: "Операция добавлена"
                                }
                            );
                            window.location.href = '/list'
                        })
                        .catch(err => console.error(err))
                })
                .catch(err => console.error(err));
        }
    };

    return (
            <Container>
                <div className="m-2 text-center">
                <span className='opHeader'><FontAwesomeIcon icon={faEuroSign} size='2x' color='gray'/>{' '}
                    {' операция обмена валют '}
                    <FontAwesomeIcon icon={faDollarSign} size='2x' color='gray'/> </span>
                </div>
                <Form className="m-md-5 m-sm-1"
                      onSubmit={handleSubmit}>
                    <Form.Row>
                        <Col xs={12} sm={4} md={4}>
                            <Form.Group>
                                <InputGroup size="lg">
                                    <Form.Control
                                        size="lg"
                                        name="type_in"
                                        type="text"
                                        value={inv.type}
                                        disabled
                                        />
                                    <Form.Control
                                        required
                                        className="w-25"
                                        size="lg"
                                        name="summ"
                                        type="number"
                                        placeholder="00"
                                        value={inv.summ}
                                        onChange={handleInvChange}
                                    />
                                    <Form.Control.Feedback>Введите сумму</Form.Control.Feedback>
                                    <Form.Control
                                        required
                                        className="w-25"
                                        size="lg"
                                        name="currency"
                                        type="text"
                                        value={inv.currency}
                                        placeholder='EUR/USD/UAH'
                                        onChange={handleInvChange}
                                        as='select'>
                                        <option></option>
                                        <option>EUR</option>
                                        <option>USD</option>
                                        <option>UAH</option>
                                    </Form.Control>
                                    <Form.Control.Feedback>Выберите валюту</Form.Control.Feedback>

                                </InputGroup>
                                <hr/>
                                <InputGroup size="lg">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Курс</InputGroup.Text>
                                    </InputGroup.Prepend>
                                <Form.Control
                                    required
                                    title='Rate'
                                    className="w-50 text-center"
                                    size="lg"
                                    name="rate"
                                    type="text"
                                    value={inv.rate}
                                    onChange={handleInvChange}
                                    />
                                    </InputGroup>
                                <Form.Control.Feedback>Установите курс</Form.Control.Feedback>
                                <hr/>

                                    <InputGroup>
                                    <Form.Control
                                        size="lg"
                                        name="type_in"
                                        type="text"
                                        value={outv.type}
                                        disabled
                                    />
                                    <Form.Control
                                        required
                                        className="w-25"
                                        size="lg"
                                        name="summ"
                                        type="number"
                                        placeholder="00"
                                        value={outv.summ}
                                        onChange={handleOutvChange}
                                    />
                                    <Form.Control.Feedback>Введите сумму</Form.Control.Feedback>
                                    <Form.Control
                                        required
                                        className="w-25"
                                        size="lg"
                                        name="currency"
                                        type="text"
                                        value={outv.currency}
                                        onChange={handleOutvChange}
                                        as='select'>
                                        <option></option>
                                        <option>EUR</option>
                                        <option>USD</option>
                                        <option>UAH</option>
                                    </Form.Control>
                                        <Form.Control.Feedback>Выберите валюту</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    size="lg"
                                    name="date"
                                    type="date"
                                    value={inv.date}
                                    onChange={handleInvChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={8} md={8}>
                            <Form.Group>
                                <InputGroup>
                                    <Form.Control
                                        size="lg"
                                        name="description"
                                        type="text"
                                        value={inv.description}
                                        placeholder='Описание'
                                        onChange={handleInvChange}
                                        as='textarea'
                                        rows='3'
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Button href='/list'
                                className="mx-auto mb-2"
                                variant="outline-secondary">
                            Назад
                        </Button>

                        <Button className="mx-auto mb-2"
                                variant="outline-primary"
                                type="submit">
                            Сохранить
                        </Button>
                    </Form.Row>
                    {showAlert.show && <ShowAlert variant={showAlert.variant} message={showAlert.message} show={showAlert.show}/>}
                </Form>
            </Container>
    )
};
export default NewExchange
