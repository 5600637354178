import React from 'react';
import './App.css';
import Login from "./Pages/Login/Login";
import {AuthContext} from './Components/Context/context';
import ProtectedResources from "./Pages/Protected/protectedPage";

const App = () => {

    const login = (usr) => {
        localStorage.setItem('user', usr);
        localStorage.setItem('access', 'granted')
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access')
    };

    return (

        <>
            <AuthContext.Provider value={{
                login: login,
                logout: logout,
                access: localStorage.getItem('access'),
                user: localStorage.getItem('user'),

            }}>
                <div>
                    <ProtectedResources/>
                    <Login/>
                </div>
            </AuthContext.Provider>
        </>

    );
};
export default App
