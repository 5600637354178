import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import CardDeck from "react-bootstrap/CardDeck";
import ContainerLoader from "../Loader/Loader";
import {faEuroSign, faMinus, faPlus, faDollarSign, faHryvnia} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CashBox = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [actions, setActions] = useState([]);
    const [error, setError] = useState(null);
    const [statement_eur, setStatement_eur] = useState({state: 0, color: 'outline-secondary'});
    const [statement_uah, setStatement_uah] = useState({state: 0, color: 'outline-secondary'});
    const [statement_usd, setStatement_usd] = useState({state: 0, color: 'outline-secondary'});
    const [lastEur, setLastEur] = useState([
        {
            description: '',
            summ: '',
            type: ''
        }
    ]);
    const [lastUsd, setLastUsd] = useState([
        {
            description: '',
            summ: '',
            type: ''
        }
    ]);
    const [lastUah, setLastUah] = useState([
        {
            description: '',
            summ: '',
            type: ''
        }
    ]);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5003';

    let eur = {state: 0, color: 'outline-secondary'};
    let uah = {state: 0, color: 'outline-secondary'};
    let usd = {state: 0, color: 'outline-secondary'};

    let eur_maxval = [];
    let usd_maxval = [];
    let uah_maxval = [];

    const accounting = (actions) => {
        return (
            actions.map((action, id) => (
                action.currency === 'EUR' ?
                    (action.type === '+' ? (eur.state = eur.state + action.summ)
                        : (eur.state = eur.state - action.summ))
                    :
                    (action.currency === 'USD' ? (action.type === '+' ?
                        (usd.state = usd.state + action.summ)
                        : (usd.state = usd.state - action.summ))
                        : (
                            (action.type === '+' ?
                                (uah.state = uah.state + action.summ)
                                : uah.state = uah.state - action.summ))))),
                eur.state < 0 ? eur.color = 'outline-danger' : 'outline-secondary',
                usd.state < 0 ? usd.color = 'outline-danger' : 'outline-secondary',
                uah.state < 0 ? uah.color = 'outline-danger' : 'outline-secondary'
        )
    };

    const last_ops = (actions) => {
        let eur_array = [];
        let usd_array = [];
        let uah_array = [];

        return (
            eur_array = actions.filter(action => action.currency === 'EUR'),
                usd_array = actions.filter(action => action.currency === 'USD'),
                uah_array = actions.filter(action => action.currency === 'UAH'),
                eur_maxval = [
                    eur_array[eur_array.length - 1],
                    eur_array[eur_array.length - 2],
                    eur_array[eur_array.length - 3],
                    eur_array[eur_array.length - 4],
                    eur_array[eur_array.length - 5]
                ],
                usd_maxval = [
                    usd_array[usd_array.length - 1],
                    usd_array[usd_array.length - 2],
                    usd_array[usd_array.length - 3],
                    usd_array[usd_array.length - 4],
                    usd_array[usd_array.length - 5]
                ],
                uah_maxval = [
                    uah_array[uah_array.length - 1],
                    uah_array[uah_array.length - 2],
                    uah_array[uah_array.length - 3],
                    uah_array[uah_array.length - 4],
                    uah_array[uah_array.length - 5]
                ]
        )
    };

    useEffect(() => {
        fetch(apiUrl + '/actions', {headers: {Authorization: 'WellcomeMyFriend'}})
            .then(res => res.json())
            .then((result) => {
                setIsLoaded(true);
                setActions(result);
                accounting(result);
                setStatement_eur(eur);
                setStatement_usd(usd);
                setStatement_uah(uah);
                last_ops(result);
                setLastEur(eur_maxval);
                setLastUsd(usd_maxval);
                setLastUah(uah_maxval);
            },)
            .catch(err => console.error(err));
    }, []);

    if (!isLoaded) {
        return (
            <div>
                <ContainerLoader/>
                {console.log(error)}
            </div>
        )
    }

    return (

        <div>
            <div>
                <CardDeck className="mt-3 p-0">
                    {console.log(lastEur)}
                    <Card className=" mycard  mx-0 p-0">
                        <Card.Body className="mycardbody m-0 p-0">
                            {/*<Card.Img variant="top" src={EURimg}/>*/}
                            <Card.Title className="mycardtitle p-0 text-center">
                                <h3>E U R</h3>
                            </Card.Title>
                            <Card.Text className="text-center mx-2 mb-2">
                                <Button href='/action/neweurin'
                                        className="mybutton mr-1 float-left p-2"
                                        variant="outline-primary"
                                        size="lg">
                                    <FontAwesomeIcon icon={faPlus} size='2x'/>
                                </Button>

                                <Button className="mysumbadge w-50" variant={statement_eur.color}>
                                    {statement_eur.state}
                                </Button>

                                <Button href='/action/neweurout'
                                        className="mybutton ml-1 float-right p-2"
                                        variant="outline-danger"
                                        size="lg">
                                    <FontAwesomeIcon icon={faMinus} size='2x'/>
                                </Button>
                            </Card.Text>
                        </Card.Body>

                        <Card.Footer className="text-muted d-block d-sm-block d-md-none d-lg-none"> <strong>{lastEur[0].type}{lastEur[0].summ}{'€ - '}</strong>{lastEur[0].description}</Card.Footer>
                        <Card.Footer className="text-muted d-none d-sm-none d-md-block d-lg-block">

                            <span><strong>{lastEur[0].type}{lastEur[0].summ}{' € - '}{lastEur[0].description}</strong></span>
                            {lastEur[1]&& <div>
                                <hr/>
                                <span><strong>{lastEur[1].type}{lastEur[1].summ}{' € - '}</strong>{lastEur[1].description}</span><br/>
                            </div>}
                            {lastEur[2] &&<span><strong>{lastEur[2].type}{lastEur[2].summ}{' € - '}</strong>{lastEur[2].description}</span>}<br/>
                            {lastEur[3] &&<span><strong>{lastEur[3].type}{lastEur[3].summ}{' € - '}</strong>{lastEur[3].description}</span>}<br/>
                            {lastEur[4] &&<span><strong>{lastEur[4].type}{lastEur[4].summ}{' € - '}</strong>{lastEur[4].description}</span>}

                        </Card.Footer>

                    </Card>
                    <Card className=" mycard  mx-0 p-0">
                        <Card.Body className="mycardbody m-0 p-0">
                            {/*<Card.Img variant="top" src={EURimg}/>*/}
                            <Card.Title className="mycardtitle p-0 text-center">
                                <h3>U A H</h3>
                            </Card.Title>
                            <Card.Text className="text-center mx-2 mb-2">
                                <Button href='/action/newuahin'
                                        className="mybutton mr-1 float-left p-2"
                                        variant="outline-primary"
                                        size="lg">
                                    <FontAwesomeIcon icon={faPlus} size='2x'/>
                                </Button>

                                <Button className="mysumbadge w-50 p-0" variant={statement_uah.color} size='sm'>
                                    {statement_uah.state}
                                </Button>

                                <Button href='/action/newuahout'
                                        className="mybutton ml-1 float-right p-2"
                                        variant="outline-danger"
                                        size="lg">
                                    <FontAwesomeIcon icon={faMinus} size='2x'/>
                                </Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted d-block d-sm-block d-md-none d-lg-none"> <strong>{lastUah[0].type}{lastUah[0].summ}{'₴ - '}</strong>{lastUah[0].description}</Card.Footer>
                        <Card.Footer className="text-muted d-none d-sm-none d-md-block d-lg-block">

                            <span><strong>{lastUah[0].type}{lastUah[0].summ}{' ₴ - '}{lastUah[0].description}</strong></span>
                            {lastUah[1] && <div>
                                <hr/>
                                <span><strong>{lastUah[1].type}{lastUah[1].summ}{' ₴ - '}</strong>{lastUah[1].description}</span><br/></div>}
                            {lastUah[2] &&<span><strong>{lastUah[2].type}{lastUah[2].summ}{' ₴ - '}</strong>{lastUah[2].description}</span>}<br/>
                            {lastUah[3] &&<span><strong>{lastUah[3].type}{lastUah[3].summ}{' ₴ - '}</strong>{lastUah[3].description}</span>}<br/>
                            {lastUah[4] &&<span><strong>{lastUah[4].type}{lastUah[4].summ}{' ₴ - '}</strong>{lastUah[4].description}</span>}

                        </Card.Footer>
                    </Card>
                    <Card className=" mycard  mx-0 p-0">
                        <Card.Body className="mycardbody m-0 p-0">
                            {/*<Card.Img variant="top" src={EURimg}/>*/}
                            <Card.Title className="mycardtitle p-0 text-center">
                                <h3>U S D</h3>
                            </Card.Title>
                            <Card.Text className="text-center mx-2 mb-2">
                                <Button href='/action/newusdin'
                                        className="mybutton mr-1 float-left p-2"
                                        variant="outline-primary"
                                        size="lg">
                                    <FontAwesomeIcon icon={faPlus} size='2x'/>
                                </Button>

                                <Button className='mysumbadge w-50' variant={statement_usd.color}>
                                    {statement_usd.state}
                                </Button>

                                <Button href='/action/newusdout'
                                        className="mybutton ml-1 float-right p-2"
                                        variant="outline-danger"
                                        size="lg">
                                    <FontAwesomeIcon icon={faMinus} size='2x'/>
                                </Button>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted d-block d-sm-block d-md-none d-lg-none"> <strong>{lastUsd[0].type}{lastUsd[0].summ}{' $ - '}</strong>{lastUsd[0].description}</Card.Footer>
                        <Card.Footer className="text-muted d-none d-sm-none d-md-block d-lg-block">
                            <span><strong>{lastUsd[0].type}{lastUsd[0].summ}{' $ - '}{lastUsd[0].description}</strong></span>
                            {lastUsd[1] && <div>
                                <hr/>
                                <span><strong>{lastUsd[1].type}{lastUsd[1].summ}{' $ - '}</strong>{lastUsd[1].description}</span><br/></div>
                            }
                            {lastUsd[2] &&<span><strong>{lastUsd[2].type}{lastUsd[2].summ}{' $ - '}</strong>{lastUsd[2].description}</span>}<br/>
                            {lastUsd[3] &&<span><strong>{lastUsd[3].type}{lastUsd[3].summ}{' $ - '}</strong>{lastUsd[3].description}</span>}<br/>
                            {lastUsd[4] &&<span><strong>{lastUsd[4].type}{lastUsd[4].summ}{' $ - '}</strong>{lastUsd[4].description}</span>}

                        </Card.Footer>
                    </Card>
                </CardDeck>
                <div className='text-center mt-3'>
                    <Button href='/action/exchange' size="lg" className='mr-4'>
                        Продажа <FontAwesomeIcon icon={faEuroSign} size='1x'/>
                    </Button>
                    <Button href='/action/inchange' size="lg" className='ml-4'>
                        Покупка <FontAwesomeIcon icon={faEuroSign} size='1x'/>
                    </Button>
                </div>

            </div>
        </div>
    )
};
export default CashBox
