import React, {useContext, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as axios from "axios";
import {AuthContext} from "../../Components/Context/context";

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5003' ;

const Login = () => {
    const authContext = useContext(AuthContext);

    const [user, setUser] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [formData, setFormData] = useState({
        login: '',
        password: '',
    });
    const [formSubmitting, setFormSubmitting] = useState(false);

    const handleChange = event => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleLogin = (event) => {
        event.preventDefault();
        setFormSubmitting(true);
        axios.post(apiUrl+'/users/check', {
            login: formData.login,
            password: formData.password
        })
            .then((response) => {
                if (response.status === 200) {
                    {authContext.login(formData.login);}
                    window.location.href = '/';
                } else {
                    alert("Неверный логин или пароль");
                    window.location.reload()
                }
                setFormSubmitting(false);
            }, (error) => {
                console.log(error);
                alert("Ошибка авторизации :"+error);
                window.location.reload()
            });
        };

    return (
        <>
        {authContext.access!=='granted'&&
        <Container>
            <Form
                className="form-login mt-5 mb-5"
                method="post"
                onSubmit={handleLogin}
            >
                <Form.Group controlId="login">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faUser}/>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            name="login"
                            autoComplete="username"
                            type="text"
                            onChange={handleChange}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="password">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faLock}/>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            placeholder="******"
                            onChange={handleChange}
                        />
                    </InputGroup>
                </Form.Group>
                <Button
                    className='mb-3 mx-auto'
                    size='lg'
                    variant="primary"
                    type="submit"
                    disabled={formSubmitting}
                >
                    Войти
                </Button>
            </Form>
        </Container>
        }
        </>
    )
}

export default Login;