import React, {useContext, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ShowAlert from "../../Components/Alert/ShowAlert";
import responsiveInputs from "../../Components/Inputs/ResponsiveInput";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins, faPlus} from "@fortawesome/free-solid-svg-icons";
import {getCurrentDate} from '../../Components/CurrentDate/currentDate'
import {AuthContext} from "../../Components/Context/context";
// import moment from "moment";

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5003' ;


const NewAction = () => {
    const [showAlert, setShowAlert] = useState({variant: 'success', show: false, message: 'Gooood!'});
    const authContext = useContext(AuthContext);
    const [action, setAction] = useState({
        type: '',
        summ: '',
        currency: '',
        user: authContext.user,
        description: '',
        date: '0000-00-00',
        is_deleted: 0,
    });
    const [error, setError] = useState(null);

    useEffect(() => {
                     // let today = moment().format('YYYY-MM-DD').toString();
                    let today=getCurrentDate();
                    setAction({...action, date:today });
                        console.log(action)
    }, []);

    const handleChange = event => {
        const {name, value} = event.target;
        setAction({...action, [name]: value});
    };

    const handleSubmit = event => {
        event.preventDefault();
        {
            const values = {
                type: action.type,
                summ: action.summ,
                currency: action.currency,
                user: action.user,
                description: action.description,
                date: action.date,
                is_deleted: 0,
            };
            console.log(values);
            axios.post(apiUrl + '/actions', values, { headers: { Authorization: 'WellcomeMyFriend' } })
                .then((response) => {
                    console.log(response);
                    setShowAlert(
                        {
                            variant: response.data.success ? 'danger' : 'success',
                            show: true,
                            message: "Операция добавлена"
                        }
                    );
                    window.location.href = '/list'
                })
                .catch(err => console.error(err))
        }
    };

    return (
        console.log(action.date),
        <Container>
            <div className="m-2">
                <span className='opHeader'><FontAwesomeIcon icon={faCoins} size='2x' color='gray'/>{' '}
                <FontAwesomeIcon icon={faPlus} size='2x' color='gray'/> {' внесение новой операции'}</span>
            </div>
            <Form className="m-md-5 m-sm-1"
                  onSubmit={handleSubmit}>
                <Form.Row>
                    <Col xs={12} sm={4} md={4}>
                        <Form.Group>
                            <InputGroup size="lg">
                                {/*<InputGroup.Prepend*/}
                                {/*    className={responsiveInputs.prepend}>*/}
                                {/*    <InputGroup.Text>+</InputGroup.Text>*/}
                                {/*</InputGroup.Prepend>*/}
                                <Form.Control
                                    size="lg"
                                    name="type"
                                    type="text"
                                    value={action.type}
                                    onChange={handleChange}
                                    as='select'>
                                    <option>...</option>
                                    <option>+</option>
                                    <option>-</option>
                                </Form.Control>
                                <Form.Control
                                    className="w-25"
                                    size="lg"
                                    name="summ"
                                    type="number"
                                    placeholder="00"
                                    value={action.summ}
                                    onChange={handleChange}
                                />
                                <Form.Control
                                    className="w-25"
                                    size="lg"
                                    name="currency"
                                    type="text"
                                    value={action.currency}
                                    placeholder='EUR/USD/UAH'
                                    onChange={handleChange}
                                    as='select'>
                                    <option>...</option>
                                    <option>EUR</option>
                                    <option>USD</option>
                                    <option>UAH</option>
                                </Form.Control>
                                {/*<InputGroup.Append*/}
                                {/*    className={responsiveInputs.prepend}>*/}
                                {/*    <InputGroup.Text>€</InputGroup.Text>*/}
                                {/*</InputGroup.Append>*/}
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                disabled
                                size="lg"
                                name="user"
                                type="text"
                                value={action.user}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                size="lg"
                                name="date"
                                type="date"
                                value={action.date}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={8} md={8}>
                        <Form.Group>
                            <InputGroup>
                                <Form.Control
                                    size="lg"
                                    name="description"
                                    type="text"
                                    value={action.description}
                                    placeholder='Описание'
                                    onChange={handleChange}
                                    as='textarea'
                                    rows='3'
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Button href='/list'
                            className="mx-auto mb-2"
                            variant="outline-secondary">
                        Назад
                    </Button>

                    <Button className="mx-auto mb-2"
                            variant="outline-primary"
                            type="submit">
                        Сохранить
                    </Button>
                </Form.Row>
                {showAlert.show && <ShowAlert variant={showAlert.variant} message={showAlert.message} show={showAlert.show}/>}
            </Form>
        </Container>
    )
};
export default NewAction
