import React from "react";
import {useContext} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import IconedTitle from "../../Components/IconedTitles/IconedTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faRedo, faPowerOff, faUserCog, faWallet} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../Context/context";

const AdminNavbar = () => {
    const authContext = useContext(AuthContext);

    const logoutHandler = () => {
        authContext.logout();
        window.location.href = '/';
    };
    return (
        <Navbar sticky="top" variant="dark" className="mynavbar w-100">
            {console.log(window.location.pathname)}
            <Nav>

                {window.location.pathname!=='/'&&<Nav.Link className="mylink mr-3" href="/">
                    {IconedTitle('Касса', <FontAwesomeIcon icon={faWallet} size='2x' color='whitesmoke'/>)}{' '}
                </Nav.Link>}
                {window.location.pathname==='/'&&
                    <Nav.Link className="mylink mr-3" href="/">
                    {IconedTitle('Обновить', <FontAwesomeIcon icon={faRedo} size='2x' color='whitesmoke'/>)}{' '}
                    </Nav.Link>
                }

                <Nav.Link className="mylink mx-4" href="/list">
                    {IconedTitle('Движения', <FontAwesomeIcon icon={faBook} size='2x' color='whitesmoke'/>)}{' '}
                    {/*<small>flows</small>*/}

                </Nav.Link>

                <Nav.Link href="/userlist"  className="mylink ml-3">
                    {IconedTitle('Пользователи', <FontAwesomeIcon icon={faUserCog} size='2x' color='whitesmoke'/>)}{' '}
                    {/*<small>users</small>*/}
                </Nav.Link>

            </Nav>

            {authContext.access==='granted' &&
            <Nav className="mylink ml-auto mx-1">
                <small>{authContext.user}</small>
                <Nav.Link onClick={logoutHandler}><FontAwesomeIcon icon={faPowerOff} size='2x' color='whitesmoke'/></Nav.Link>
            </Nav>}
        </Navbar>
    )
};
export default AdminNavbar
